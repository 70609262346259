
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

body {
  margin: 0;
  font-family: 'Jost', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ::selection {
  background: rgba(255, 193, 7, 0.5)
} */

.clickable:hover {
  cursor: pointer;
}

.flex-grow-1{
  flex-grow: 1;
}

.flex-row{
  display: flex;
  flex-direction: row;  
}

.chip{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: fit-content;
  font-weight: 500;
  border-radius: 25px;
  background-color: #f1f1f1;
}

.chip-close{
  margin-left: 5px;
}

.chip-close:hover{
  cursor: pointer;
}

.no-wrap{
  white-space: nowrap;
}


.grow-height{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.no-outline{
  outline: none !important;
  box-shadow: none !important;
}

.center-text{
  text-align: center;
}

.small-text{
  font-size: 0.8rem;
}

.sticky{
  position: sticky;
  top: 0; 
}